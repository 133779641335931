<template>
  <div class="wywithdrwdeposit">
    <van-nav-bar
      :title="$t('MYWITHDRAWDEPOSIT')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div v-if="empty" class="empty">
      <van-empty
        class="custom-image"
        :image="emptyImg"
      >
      <div slot="description" >
        <div class="statuttop">{{$t('NOMYWITHDRAWDEPOSIT')}}</div>
        <!-- <div class="statutbottom">{{$t('CURRENTMONTHNEEDNOTBORRORW')}}</div> -->
      </div>
      </van-empty>
    </div>
    <div v-else v-for="(item, index) in borrowList" :key="index" @click="godetail(item.id)" class="borrowbox">
      <div class="borowTop">
        <van-cell center>
          <div class="title" slot="title">
            {{$t('TIXIANID')}}:
          </div>
          <!-- <div class="showstatus" slot="default">{{item.status}}</div> -->
          <div slot="label">{{item.id}}</div>
          <van-icon color="#666" size="20" slot="right-icon" name="arrow" />
        </van-cell>
      </div>
      <div class="borowBottom">
        <van-cell center>
          <div slot="icon" class="imgbox">
            <van-image round class="iconimg" :src="B_icon" />
          </div>
          <div class="title" slot="title">
            {{$t('MYWITHDRAWDEPOSITMONEY')}}：{{Number(item.money).toFixed(2)}}{{$t('UNIT')}}
          </div>
          <div class="fon16" slot="label">
            <div class="borrowTime">{{$t('MYWITHDRAWDEPOSITIME')}}:{{item.createTime}}</div>
            <!-- <div class="everyBorrow">{{$t('MYWITHDRAWDEPOSITMONEY')}}：₱{{item.monthly}}</div> -->
          </div>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
import { getmyWithdrawal } from '@/api/my'
import './css/my.css'
export default {
  name: 'wywithdrwdeposit',
  data () {
    return {
      borrowList: null,
      empty: null,
      emptyImg: require('./img/p_01 (1).png'),
      B_icon: require('../My/img/money.jpg')
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    godetail (id) {
      this.$router.push({
        name: 'wywithdrwdepositdetail',
        params: {
          id
        }
      })
    },
    async loaddata () {
      const { data } = await getmyWithdrawal()
      if (data.data.length === 0) {
        this.empty = true
      } else {
        this.empty = false
        this.borrowList = data.data
      }
      console.log(data)
    }
  },
  created () {
    this.loaddata()
  }
}
</script>

<style scoped>
.borrowbox {
  margin-top: 10px;
}
.borowTop {
  border-bottom: 1px solid #e4e4e4;
}
.showstatus {
  color: red;
}
.imgbox {
  margin-right: 10px;
  box-sizing: border-box;
  padding: 5px;
  width: 60px;
  height: 60px;
  border: 1px solid #e4e4e4;
}
.iconimg {
  width: 100%;
  height: 100%;
}
.fon16 {
  font-size: 14px;
  color: #198eed;
}
</style>
